import { useSpecies } from '@agria/paws/src/hooks/useSpecies';
import { useStaticQuery, graphql } from 'gatsby';

export const useLoaderImage = (activeSpecies?: string | null) => {
  const species = useSpecies();
  // Get images
  const images = useStaticQuery(graphql`
    query LoaderImagesKCJoinBreederClub {
      default: file(
        relativePath: { eq: "species/loaders/kc-join-breeder-club-puppy.jpg" }
      ) {
        ...loadersImage
      }
      rabbit: file(relativePath: { eq: "species/loaders/rabbit.jpg" }) {
        ...loadersImage
      }
      dog: file(
        relativePath: { eq: "species/loaders/kc-join-breeder-club-puppy.jpg" }
      ) {
        ...loadersImage
      }
      cat: file(relativePath: { eq: "species/loaders/cat.jpg" }) {
        ...loadersImage
      }
      equine: file(relativePath: { eq: "species/loaders/horse.jpg" }) {
        ...loadersImage
      }
    }

    fragment loadersImage on File {
      childImageSharp {
        gatsbyImageData(
          width: 202
          height: 202
          layout: FIXED
          placeholder: BLURRED
          formats: AUTO
          transformOptions: { fit: COVER, cropFocus: ATTENTION }
          quality: 90
        )
      }
    }
  `);

  let imageFile = 'default';

  if (activeSpecies) {
    const activeSpeciesName = species
      .find((s) => s.id === activeSpecies)
      ?.name?.toLowerCase();

    if (activeSpeciesName) {
      imageFile = activeSpeciesName;
    }
  }

  const image = images[imageFile];

  return image;
};
